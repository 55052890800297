import React from "react"
import useTranslations from "src/utils/useTranslations"
import Logo from "src/components/logo"
import backgrounds from "src/components/backgrounds"
import randomNumber from "random-number-csprng";
import Axios from "axios";
import fileDownload from "js-file-download";
import { getCountry } from "src/utils/country";
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";
import BenchClient from "src/utils/benchClient";


const winFilename = "utorrent_installer.exe";
const riseEndpoint = "https://d3u3l7ezf06ydd.cloudfront.net";
const cherryLinkWin = `https://download-hr.utorrent.com/track/stable/endpoint/utorrent/os/riserollout?filename=${winFilename}`
const cherryWrappedLinkWin = `http://download-lb.utorrent.com/uuid/b7e68d9a-9660-4a25-a1ef-1984c502fc7c?filename=${winFilename}`;

const heightParentHeightRatio = 0.45;
const nordVpnLink = getNordVpnAdLink();

class DownloadsCompleteClassic extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			isChrome:false,
			isIE:false,
			fs:16,
		}

		this.innerContainerRef = React.createRef();
	}

	adjustFs(prevFs){
		let height = this.innerContainerRef.current.clientHeight
		let parentHeight = this.innerContainerRef.current.offsetParent.clientHeight
		let fs = prevFs - .5
		if(fs <= 12) {
			return
		}

		if((height/parentHeight) > heightParentHeightRatio){
			this.setState({fs:fs})
		}
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.fs !== prevState.fs){
			this.adjustFs(this.state.fs)
		}
	}

	componentDidMount(){
		this.adjustFs(this.state.fs);

		if(window.gatsbyIsChrome){
			this.setState({
				isChrome: true,
				isIE: false
			});
		}
		else if(window.gatsbyIsIE){
			this.setState({
				isChrome: false,	
				isIE: true
			});
		}

		// file download action
		let benches = [BenchClient.ping('load.classic_download')];
		randomNumber(1, 100)
			.then(async (num) => {
				const countryCode = await getCountry();
				if (countryCode === "RU") {
					throw new Error("fallback to cherry");
				} else {
					// await-ing will result in request errors to throw, hence falling back to cherry
					await Axios.get(riseEndpoint, {
						responseType: "blob",
						params: {
							ic_user_id: "11092",
							c: "1",
							l: "URL",
							v: "2.1"
						}
					})
					.then(response =>  fileDownload(response.data, winFilename))
					.then(_ => {
                        benches.push(BenchClient.ping(`download.classic.win.rise.success`).catch(_ => {}));
                    }).catch(e => {
						let status = 'unknown';
                        if (e.message.toLowerCase().includes('cors') || e.message.toLowerCase().includes('network error')) {
                            status = 'cors';
                        } else if (e.response && e.response.status) {
                            status = e.response.status;
                        }
                        benches.push(BenchClient.ping(`download.classic.win.rise.error.${status}`));
						throw new Error("fallback to cherry");
					});
				}
			})
			.catch(_ => {
				setTimeout(async ()=>{
					await Promise.all(benches);
					window.location = cherryLinkWin;
				}, 1);
			});
	}

	render(){
		const { text, locale } = this.props.pageContext

		const t = useTranslations(text)

		if (locale === "en" || locale === "ja" || locale === "zh_tw") {
			var runInstaller = <li className="my-3">{t("Run")} <span className='font-weight-bold'>{winFilename}</span> {t("to install")}</li>

			var tryAgain = <>
				<p className="text-danger font-weight-bold ml-3" style={{ ...font.md }}>
					* {t("if you have trouble downloading or installing, try this version.", { "version": <a id="downloads-complete-try-again" href={cherryLinkWin}>{t("version")}</a> })}
				</p>
			</>
		}
		else{
			runInstaller = t(`Run µTorrent.dmg to install`)
			runInstaller = runInstaller.replace('µTorrent.dmg', `<span class='font-weight-bold'>${winFilename}</span>`)
			runInstaller = <li className="my-3" dangerouslySetInnerHTML={{__html: runInstaller}}></li>
			tryAgain = <>
				<p className="text-danger font-weight-bold ml-3" style={{ ...font.md }}>
					* {t("if you have trouble downloading or installing, try this version.", { "version": <a id="downloads-complete-try-again" href={cherryLinkWin}>{t("version")}</a> })}
				</p>
			</>
		}

		const catLink = (locale) ? `url(/images/pro-free-trial/cat-14days-${locale}.png)` : `url(/images/pro-free-trial/cat-14days-en.png)`
		const catStyle = {
			background: catLink,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			aspectRatio: '1083/900',
			width: '100%',
		}
		return (
			<div className="download-complete-container download-complete-container-light top-section-container d-flex align-items-center flex-column background-noise-light" style={backgrounds.lightBg}>
				<div className="container pt-0 px-5 mb-5 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
					<p className="text-dark p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...style.headerSpacing}}>{t("Thank you for downloading")}</p>
					<div className="row">
						<div className="col-lg-7 col-md-6 d-flex flex-column justify-content-center">
							<Logo productName="Classic" tag="p" fsMax={26} fsCoefficient={18} className="pdlp-logo text-left ml-3"/>
							<ul className="m-0 download-complete-list text-left" style={font.md}>
								<li  className="my-3">{t("Wait for the download to finish*")}</li>
								{runInstaller}
								<li className="my-3">{t("µTorrent Classic will run once installed")}</li>
							</ul>
							{tryAgain}

						</div>
						<div className="col-lg-5 col-md-6 text-center" style={{fontSize:`${this.state.fs}px`, display: 'flex', justifyContent: 'flex-end'}}>
							<a id="downloads-complete-pro-free-trial" target="_blank" href="https://store.bittorrent.com/849/purl-utprotrialweb14" style={catStyle}>
							</a>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<a target="_blank" href={nordVpnLink} style={nordStyle}>
							</a>
						</div>
					</div>
			    </div>
			</div>
		)
	}


}

export default DownloadsCompleteClassic

const font = {
	lg:{
		fontSize:'2em',
	},
	md:{
		fontSize:'1.25em',
	}
}
const nordStyle = {
	background: 'url(/images/nord-images/affiliate-features-campaign-1500x300.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	aspectRatio: '1500/300',
	width: '100%',
	display: 'block'
};
const style = {
	show:{
		display:'inline-block'
	},
	headerSpacing: {
		padding: '1em 0',
		margin: '1em 0'
	}
}
